var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products"},[_c('div',{staticClass:"product-header",class:{ active: _vm.filtered }},[_c('Filtrele',{attrs:{"filter":_vm.filter},on:{"filtered":_vm.handlerTemizle,"handlerFilter":_vm.handlerFilter}})],1),_c('div',{staticClass:"product-body"},[(!_vm.rows.length)?_c('b-alert',{staticClass:"text-center m-2",attrs:{"show":"","variant":"warning"}},[_c('h5',[_vm._v("Bilgi")]),_c('hr'),_c('p',[_vm._v("Gösterilecek veri yok")])]):_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
        enabled: false,
        perPage: 1000,
      },"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'urun_k_no')?_c('span',[_c('router-link',{attrs:{"to":{
              name: 'urun-hareketler',
              params: { k_no: props.row.k_no },
            }}},[_c('span',{staticClass:"d-block text-dark font-weight-bold"},[_vm._v(_vm._s(props.row.k_no))])])],1):(props.column.field === 'urun_adi')?_c('span',[_vm._v(" "+_vm._s(props.row.urun_adi[_vm.defaultDil].baslik)+" ")]):(props.column.field === 'toplam_adet')?_c('span',[_c('h5',[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(_vm._s(props.row.cikis))])],1)]):(props.column.field === 'action')?_c('span',{staticClass:"float-right px-1"},[_c('b-button-group',{staticClass:"pb-25",attrs:{"size":"sm"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Ürün Hareketleri'),expression:"'Ürün Hareketleri'",modifiers:{"hover":true,"top":true}}],staticClass:"rounded-0",attrs:{"variant":"primary","to":{
                name: 'urun-hareketler',
                params: { k_no: props.row.k_no },
              }}},[_c('i',{staticClass:"fad fa-eye"})])],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }